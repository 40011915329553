<template>
  <section class="pt-xl bg-dark">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 text-center">
          <img
            src="@/assets/img/about_us_main.png"
            class="img-fluid"
            alt="Bujin.tv Karatekas"
          />
        </div>

        <div class="col-12 col-lg-6 text-justify text-white">
          <div class="text-center">
            <logo variation="white_full" class="mb-sm"></logo>
          </div>

          <p>
            Bujin.tv is all about martial arts streaming content with hundreds
            of videos available for viewing including full seminars, kata and
            applications classes, training and fitness videos, documentaries,
            interviews and podcasts. More videos are added every week. Our team
            also works directly with top class instructors around the world to
            deliver live streaming seminars directly into your home or dojo. We
            are dedicated to building bridges between our passionate community
            of martial arts enthusiasts and top class teachers who represent the
            best of their disciplines.
          </p>
          <p>
            Bujin.tv was founded in 2020 by Christian Romanelli and James
            Pankiewicz, both martial arts practitioners and dojo owners. We love
            what we do and deeply believe promoting martial arts practice is
            hugely important and beneficial to all. We want all people to have
            equal access to this knowledge and tradition.<br />
            We are committed to supporting our community of expert teachers and
            content creators. Bujin.tv shares revenue collected from
            subscription fees directly back to our content partners every month.
            In addition we work with them to deliver live seminar events via our
            platform at no cost to our teachers and instructors.
          </p>
          <p>
            We built Bujin.tv based on a mission to bring the values and
            learning of traditional martial arts culture together with the
            reality of our modern, fast changing world.
          </p>
          <p>
            The Bujin.tv Values Statement - our commitment to the development of
            Bujin.tv
          </p>
          <p>
            <span class="font-weight-bold">Curiosity </span>- we seek to
            discover the best and always be looking ahead to the future. We will
            foster the curiosity of our users through delivery of fascinating,
            challenging, captivating content.<br />
            <span class="font-weight-bold">Innovation </span>- we keep using
            creative thinking and develop new solutions. We continually invest
            in innovation.<br />
            <span class="font-weight-bold">Respect </span>- we deeply respect
            the history, culture and values of our subject matter, partners and
            users.<br />
            <span class="font-weight-bold">Balance </span>- we seek for balance
            between innovation and tradition, between traditional and sport
            approach to Karate.<br />
            <span class="font-weight-bold">Community </span>- we are committed
            to the martial arts community and its needs. We will make positive
            contributions to our community.<br />
            <span class="font-weight-bold">Impact </span>- we will be a force
            for positive change in our community and a brand that leaves a
            lasting impression. We will grow through the strength and depth of
            our reputation.<br />
            <span class="font-weight-bold">Support </span>- we will reinvest in
            our partners and support their development. We will become a major
            force in helping our partners and users to survive and thrive.<br />
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Logo from "@/components/Logo";

export default {
  name: "about-us",

  components: { Logo },
};
</script>
