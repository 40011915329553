<template>
  <div class="container mt-5" id="contactus">
    <div class="row mt-5">
      <div class="col text-center">
        <h1>Contact Us</h1>
      </div>
    </div>
    <div class="row" id="Contact Form">
      <div class="col">
        <form v-if="!registered" class="row w-100" @submit.prevent="register">
          <h2
            class="w-100 text-md text-sm-lg text-center font-weight-600 mb-md"
          >
            👋 Get in touch and let us know any inquiry or feedback 🧐 you might
            have<br />
          </h2>

          <div class="col-12 col-sm-6 mb-2">
            <base-input
              v-model="name"
              input-classes="border-primary font-weight-600"
              placeholder="First Name"
              :error="errorFor('name')"
            ></base-input>
          </div>

          <div class="col-12 col-sm-6 mb-2">
            <base-input
              v-model="lastname"
              input-classes="border-primary font-weight-600"
              placeholder="Last Name"
              :error="errorFor('last_name')"
            ></base-input>
          </div>

          <div class="col-12 mb-2">
            <base-input
              v-model="email"
              type="email"
              input-classes="border-primary font-weight-600"
              placeholder="Email Address"
              :error="errorFor('email')"
            ></base-input>
          </div>

          <div class="col-12 mb-4">
            <textarea
              v-model="message"
              class="form-control border-primary font-weight-600"
              rows="3"
              placeholder="Message"
              :error="errorFor('message')"
            ></textarea>

            <div
              class="text-danger invalid-feedback d-block"
              v-if="errorFor('message')"
            >
              {{ errorFor("message") }}
            </div>
          </div>

          <div class="col-12 col-sm-4 mx-auto mb-sm">
            <base-button
              type="primary"
              native-type="submit"
              class="col-12"
              :disabled="processing"
            >
              Send Feedback 🥋
            </base-button>
          </div>

          <div v-if="apiError" class="w-100">
            <p class="text-sm text-center text-danger font-weight-600">
              {{ apiError }}
            </p>
          </div>
        </form>

        <div v-else class="text-center">
          <img
            src="~@/assets/img/youkoso.svg"
            :style="{ maxWidth: '350px' }"
            alt="Thanks for subscribing"
            class="img-fluid"
          />

          <h2 class="text-md text-sm-lg text-center font-weight-600 mb-sm">
            We got you...🥋
          </h2>

          <p
            class="text-lg text-sm-xl text-center lh-130 font-weight-400 mb-md"
          >
            We have received your information successfully and will come back to
            you soon!
          </p>

          <div class="d-flex align-items-center justify-content-center">
            <span class="mr-1">Follow us</span>
            <a
              href="https://www.instagram.com/bujintv"
              class="mr-1"
              target="_blank"
            >
              <img
                src="~@/assets/img/instagram_icon_black.svg"
                alt="Bujin.tv Instagram"
                :style="{ width: '40px' }"
              />
            </a>
            <a href="https://www.facebook.com/bujintv" target="_blank">
              <img
                src="~@/assets/img/facebook_icon_black.svg"
                alt="Bujin.tv Facebook"
                :style="{ width: '40px' }"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import handlesApiErrors from "@/mixins/handlesApiErrors";
export default {
  name: "ContactUs",
  mixins: [handlesApiErrors],
  data() {
    return {
      name: "",
      lastname: "",
      email: "",
      message: "",
      processing: false,
      registered: false,
    };
  },

  methods: {
    async register() {
      this.clearApiErrors();

      this.processing = true;

      try {
        await this.axios.post("/content-provider-requests", {
          name: this.name,
          last_name: this.lastname,
          email: this.email,
          message: this.message,
        });
      } catch (e) {
        this.handleApiErrors(e);

        this.processing = false;

        return;
      }

      this.registered = true;
    },
  },
};
</script>

<style scoped lang="scss"></style>
